import * as React from "react";

import { __, Context } from "../../appcontext";
import { clearLoginCookie, getVersion, Helper, isNewVersionAvailable, sleep, Version } from "../../common/common";
import { LoginData, LoginType, SessionsInfo } from "../../common/communication.base";
import * as CryptoJS from "crypto-js";
import { K2Key } from "../../app";
import css from "./Login.scss";
import { KeyboardInput_LocalToUSKeys } from "../Input/K2KeyboardInput";
import { SessionBox } from "./K2SessionBox";
import MediaQuery from "react-responsive";
import { K2BusyIndicator } from "../BusyIndicator/K2BusyIndicator";

const pinKeyboard = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "CLEAR", "0", "DEL"];
const svg_no_connect =
  '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"><g id="surface1"><path d="M15.508,2.094l-1.414,1.414l2.523,2.523c-0.207,-0.011 -0.406,-0.031 -0.617,-0.031c-2.391,-0 -4.582,0.867 -6.305,2.281l-5.988,-5.988l-1.414,1.414l26,26l1.414,-1.414l-5.988,-5.988c1.418,-1.723 2.281,-3.914 2.281,-6.305c0,-1.5 -0.301,-2.898 -0.902,-4.098l-1.5,1.5c0.3,0.797 0.402,1.7 0.402,2.598c0,1.84 -0.641,3.535 -1.699,4.891l-11.192,-11.196c1.356,-1.054 3.051,-1.695 4.891,-1.695c0.188,0 0.371,0.016 0.559,0.031l-2.465,2.461l1.414,1.414l4.906,-4.906l-4.906,-4.906Zm-8.348,9.254c-0.738,1.39 -1.16,2.976 -1.16,4.652c0,1.5 0.301,2.898 0.898,4.098l1.5,-1.5c-0.199,-0.797 -0.398,-1.7 -0.398,-2.598c0,-1.121 0.234,-2.188 0.656,-3.156l-1.496,-1.496Zm9.039,9.039l-4.613,4.613l4.906,4.906l1.414,-1.414l-2.527,-2.523c0.207,0.011 0.414,0.031 0.621,0.031c1.676,0 3.262,-0.422 4.652,-1.16l-1.496,-1.496c-0.968,0.422 -2.035,0.656 -3.156,0.656c-0.187,0 -0.371,-0.012 -0.559,-0.027l2.172,-2.172l-1.414,-1.414Z" style="fill:#f44;fill-rule:nonzero;"/></g></svg>';
const svg_eye = (
  <svg version="1.1" id="eye" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32">
    <path
      id="eye_1_"
      d="M16,13c1.7,0,3,1.3,3,3s-1.3,3-3,3s-3-1.3-3-3S14.3,13,16,13z M30.7,16.7c-0.3,0.3-6.7,6.6-13.6,7.2C16.7,24,16.4,24,16,24s-0.7,0-1.1-0.1C8,23.3,1.6,17,1.3,16.7L1,16l0.3-0.7C1.6,15,8,8.7,14.9,8.1C15.3,8,15.6,8,16,8s0.7,0,1.1,0.1c6.9,0.6,13.3,7,13.6,7.2L31,16L30.7,16.7z M16.8,10c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0C11.7,10.4,9,12.9,9,16s2.7,5.6,6.2,6c0.3,0,0.5,0,0.8,0s0.5,0,0.8,0c3.5-0.3,6.2-2.9,6.2-6C23,12.9,20.3,10.4,16.8,10z M3,16c0.9,0.8,2.7,2.2,4.9,3.4C7.3,18.4,7,17.2,7,16s0.3-2.4,0.9-3.4C5.7,13.8,3.9,15.2,3,16z M29,16c-0.9-0.8-2.7-2.2-4.9-3.4c0.5,1,0.9,2.2,0.9,3.4c0,1.2-0.3,2.4-0.9,3.4C26.3,18.2,28.1,16.8,29,16z"
    ></path>
  </svg>
);
const svg_no_eye = (
  <svg version="1.1" id="eye.no_1_" x="0px" y="0px" viewBox="0 0 32 32" enableBackground="new 0 0 32 32">
    <path
      id="eye.no"
      d="M31,16l-0.3-0.7C30.4,15,24,8.7,17.1,8.1C16.7,8,16.4,8,16,8c-0.4,0-0.7,0-1.1,0.1c-1.5,0.1-3,0.5-4.4,1.1L6.8,5.4L5.4,6.8l19.8,19.8l1.4-1.4L23.4,22c4.1-2.1,7.1-5.1,7.3-5.3L31,16z M18.7,17.3c0.2-0.4,0.3-0.8,0.3-1.3c0-1.7-1.3-3-3-3c-0.5,0-0.9,0.1-1.3,0.3l-2.4-2.4c0.9-0.5,1.9-0.8,2.9-0.9c0.3,0,0.5,0,0.8,0c0.3,0,0.5,0,0.8,0c3.5,0.3,6.2,2.9,6.2,6c0,1.5-0.6,2.8-1.7,3.9L18.7,17.3z M24.1,19.4c0.6-1,0.9-2.2,0.9-3.4c0-1.2-0.3-2.4-0.9-3.4c2.2,1.2,4,2.6,4.9,3.4C28.1,16.8,26.3,18.2,24.1,19.4z M16.8,22c-0.3,0-0.5,0-0.8,0c-0.3,0-0.5,0-0.8,0C11.7,21.6,9,19.1,9,16c0-0.7,0.2-1.5,0.5-2.1l-2.8-2.8c-3.1,1.9-5.2,4-5.4,4.2L1,16l0.3,0.7C1.6,17,8,23.3,14.9,23.9c0.3,0,0.7,0.1,1.1,0.1c0.4,0,0.7,0,1.1-0.1c0.7-0.1,1.4-0.2,2.1-0.4l-1.7-1.7C17.2,21.9,17,21.9,16.8,22z M7.9,19.4c-2.2-1.2-4-2.6-4.9-3.4c0.9-0.8,2.7-2.2,4.9-3.4C7.3,13.6,7,14.8,7,16C7,17.2,7.3,18.4,7.9,19.4z"
    ></path>
  </svg>
);
const svg_keyboard = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13.8" viewBox="0 0 24 13.8">
    <path
      id="icons8_keyboard"
      d="M2.6,7A1.579,1.579,0,0,0,1,8.533V19.267A1.579,1.579,0,0,0,2.6,20.8H23.4A1.579,1.579,0,0,0,25,19.267V8.533A1.579,1.579,0,0,0,23.4,7Zm0,1.533H23.4V19.267H2.6Zm1.6,1.533V11.6H5.8V10.067Zm3.2,0V11.6H9V10.067Zm3.2,0V11.6h1.6V10.067Zm3.2,0V11.6h1.6V10.067Zm3.2,0V11.6h1.6V10.067Zm3.2,0V11.6h1.6V10.067Zm-16,3.067v1.533H7.4V13.133Zm4.8,0v1.533h1.6V13.133Zm3.2,0v1.533h1.6V13.133Zm3.2,0v1.533H17V13.133Zm3.2,0v1.533h3.2V13.133ZM4.2,16.2v1.533H7.4V16.2Zm4.8,0v1.533h8V16.2Zm9.6,0v1.533h3.2V16.2Z"
      transform="translate(-1 -7)"
      fill="#fff"
    />
  </svg>
);
const svg_pin = (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16.8" viewBox="0 0 15 16.8">
    <path
      id="icons8_keypad"
      d="M8,6V8.4h3V6Zm6,0V8.4h3V6Zm6,0V8.4h3V6ZM8,10.8v2.4h3V10.8Zm6,0v2.4h3V10.8Zm6,0v2.4h3V10.8ZM8,15.6V18h3V15.6Zm6,0V18h3V15.6Zm6,0V18h3V15.6Zm-6,4.8v2.4h3V20.4Z"
      transform="translate(-8 -6)"
      fill="#fff"
    />
  </svg>
);
const svg_card = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14.8" viewBox="0 0 20 14.8">
    <path
      id="icons8_card_payment"
      d="M10.692,7a2.307,2.307,0,0,0-1.731.779,4.219,4.219,0,0,0-2.38,1.29l-.264.243c-.556.472-2.227,1.877-3.317,2.8v2.02c.18-.152,3.474-2.924,4.3-3.627l.288-.243a8.059,8.059,0,0,1,.793-.633v5.234c-.288.161-.841.462-.841.462l.721,1.363s2.945-1.637,3.293-1.826a4.36,4.36,0,0,1,2.091-.852.917.917,0,0,1,.889,1,.869.869,0,0,1-.216.657,3.119,3.119,0,0,1-.745.609,12.871,12.871,0,0,0-1.755,1.436l-.048.073-.048.049a4.672,4.672,0,0,1-4.087,2.41H3V21.8H7.639A6.314,6.314,0,0,0,12.9,18.83s.556-.523,1.058-.925h6.731A2.331,2.331,0,0,0,23,15.568V9.337A2.331,2.331,0,0,0,20.692,7Zm0,1.558h10a.764.764,0,0,1,.769.779v.779H10.692v1.558H21.462v3.895a.764.764,0,0,1-.769.779H15.74a2.709,2.709,0,0,0,.337-1.339,2.5,2.5,0,0,0-2.428-2.556A5.456,5.456,0,0,0,10.813,13.5c-.105.058-.526.283-.889.487V9.337A.764.764,0,0,1,10.692,8.558Z"
      transform="translate(-3 -7)"
      fill="#fff"
    />
  </svg>
);
const svg_wireless = (
  <svg xmlns="http://www.w3.org/2000/svg" width="49.56" height="35.05" viewBox="0 0 49.56 35.05">
    <path
      id="icons8_online_1"
      d="M8.276,5.406a24.793,24.793,0,0,0,0,35.05L10.6,38.13a21.459,21.459,0,0,1,0-30.4Zm35.05,0L41,7.733a21.459,21.459,0,0,1,0,30.4l2.326,2.326A24.794,24.794,0,0,0,43.326,5.406ZM15.255,12.385a14.953,14.953,0,0,0,0,21.092L17.633,31.1a11.506,11.506,0,0,1,0-16.336Zm21.092,0-2.378,2.378a11.506,11.506,0,0,1,0,16.336l2.378,2.378A14.953,14.953,0,0,0,36.347,12.385ZM25.8,17.968a4.963,4.963,0,1,0,4.963,4.963A4.989,4.989,0,0,0,25.8,17.968Zm0,3.309a1.654,1.654,0,1,1-1.654,1.654A1.633,1.633,0,0,1,25.8,21.277Z"
      transform="translate(-1.021 -5.406)"
      fill="#084f91"
    />
  </svg>
);
const svg_clear = (
  <svg xmlns="http://www.w3.org/2000/svg" width="23.449" height="17.196" viewBox="0 0 23.449 17.196">
    <path
      id="delete_password"
      d="M26.667,120H48.553v6.791h-.024a6.25,6.25,0,1,1-10.919,4.152,6.157,6.157,0,0,1,.22-1.563H26.667Zm17.2,6.253a4.69,4.69,0,1,0,4.69,4.69A4.68,4.68,0,0,0,43.863,126.253Zm-1.783,1.783,1.783,1.784,1.783-1.784,1.124,1.124-1.783,1.783,1.783,1.783-1.124,1.124-1.783-1.783L42.08,133.85l-1.124-1.124,1.784-1.783-1.784-1.783Zm-13.85-6.473v6.253H38.465a6.2,6.2,0,0,1,8.525-2.271v-3.982Zm2.3,1.563a1.58,1.58,0,1,1-1.58,1.58A1.58,1.58,0,0,1,30.531,123.127Zm3.908,0a1.58,1.58,0,1,1-1.58,1.58A1.58,1.58,0,0,1,34.439,123.127Zm3.908,0a1.58,1.58,0,1,1-1.58,1.58A1.58,1.58,0,0,1,38.348,123.127Z"
      transform="translate(-26.667 -120)"
      fill="#fff"
      fillRule="evenodd"
    />
  </svg>
);
const svg_del = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14.996" viewBox="0 0 22 14.996">
    <path
      id="icons8_clear_symbol"
      d="M10.092,7l-.26.234L3.167,13.9l-.573.6.573.6,6.665,6.665.26.234h14.5V7Zm.7,1.666H22.927V20.33H10.795L4.963,14.5Zm2.265,2.291-1.172,1.172L14.258,14.5l-2.369,2.369,1.172,1.172,2.369-2.369L17.8,18.039l1.172-1.172L16.6,14.5l2.369-2.369L17.8,10.957l-2.369,2.369Z"
      transform="translate(-2.594 -7)"
      fill="#fff"
    />
  </svg>
);

interface ReconnectProps {
  reconnectFce: () => void;
}

interface ErrorMsg {
  message: string;
}

interface LoginViewProps {
  headerImg: { webm: string; mp4: string };
  footerImg: string;
  ver: string;
  ToSLink: string;
  privacyPolicyLink: string;
  licenseHolder: string;
  more?: string;
  reconnect?: ReconnectProps;
  error?: ErrorMsg;
  localizationVersion: number;
}

enum LoginState {
  none,
  checkInfo,
  waitToTwoFactorConfirm,
  selectSession,
  login,
  success,
  error,
  reconnect,
  wait2FResult,
  closeSession,
}

interface LoginViewState {
  userId?: string;
  password?: string;
  loginState: LoginState;
  requestState?: LoginState;
  errorMessage?: string;
  revealPassword: boolean;
  version: string;
  loginData?: LoginData;
  nextTryIn: number;
  sessions?: Array<SessionsInfo>;
  sessionsVersion?: number;
  sessionId?: string;
  wsid?: string;
  loginType: LoginType;
  localizationVersion: number;
}

// const MAX_RECONNECT_COUNT = 3;
const RECONNECT_DELAY = 5; // seconds

export default class LoginView extends React.PureComponent<LoginViewProps, LoginViewState> {
  inputRef: React.RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();
  infoWrap: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
  focusField: HTMLInputElement;
  rememberLogin = false;
  errorTries = 0;
  // reconnectCountExceeded = false;
  autoLogin = false;
  countdown = 0;

  constructor(props: LoginViewProps) {
    super(props);
    this.state = {
      loginState: props.error ? LoginState.error : props.reconnect ? LoginState.reconnect : LoginState.none,
      revealPassword: false,
      version: "",
      userId: Context.DeviceInfo.UserName,
      password: "",
      nextTryIn: 0,
      loginType: Context.DeviceInfo.DefaultLoginType,
      localizationVersion: 0,
    };
  }

  async componentDidMount() {
    if (!this.props.reconnect) {
      const version = await getVersion();
      Context.getApplication().Version = version;
      this.setState({ version: version });
    }

    this.focusField ? this.focusField.focus() : null;

    if (Context.DeviceInfo.AutoLogin == "0") {
      clearLoginCookie(true);
    }

    if (Context.DeviceInfo.QuickLogin) {
      this.autoLogin = Context.DeviceInfo.AutoLogin === "1";
      this.setState({
        userId: Context.DeviceInfo.QuickLogin,
        password: undefined,
        loginType: LoginType.pin,
        loginState: this.props.reconnect ? LoginState.login : LoginState.checkInfo,
      });
      return;
    }

    const loginCookie = document.cookie.split("; ").find((cookie) => cookie.includes("login"));

    if (!loginCookie) return;

    let [name, password, autoLogin] = [...loginCookie.split(",")];
    name = name.replace("login=", "");

    if (autoLogin === "true") {
      const pass = CryptoJS.AES.decrypt(password, K2Key).toString(CryptoJS.enc.Utf8);
      this.autoLogin = autoLogin === "true";
      this.rememberLogin = true;
      this.setState({ userId: name, password: pass, loginState: this.props.reconnect ? LoginState.login : LoginState.checkInfo });
    }
  }

  render() {
    let content: JSX.Element = null;
    if (this.state.loginState === LoginState.none) {
      content = this.getLoginForm();
    } else {
      content = this.getLoginStateInfo();
    }
    return (
      <div onKeyDown={this.initTypeActions} tabIndex={-1}>
        <div className="login-container animation-container">
          <div className="login-form">
            {!this.props.reconnect && this.state.loginState != LoginState.selectSession && (
              <div className="logo-container">
                <video autoPlay muted playsInline>
                  <source src={this.props.headerImg.webm} type="video/webm" />
                  <source src={this.props.headerImg.mp4} type="video/mp4" />
                </video>
              </div>
            )}
            <div className="login-content">{content}</div>
          </div>
        </div>
      </div>
    );
  }

  private getLoginStateInfo(): JSX.Element {
    let img: string = null;
    let text: string = null;
    let cls: string = null;
    switch (this.state.loginState) {
      case LoginState.reconnect:
        if (!this.autoLogin) {
          if (this.props.reconnect.reconnectFce) {
            return (
              <div className="login-info reconnect-info">
                <div className="login-reconnect">
                  <div className="svg" dangerouslySetInnerHTML={{ __html: svg_no_connect }} />
                  <span>{__("loginNoConnection")}</span>
                </div>
                <button
                  className="loginBtn"
                  onClick={() => {
                    this.props.reconnect.reconnectFce.call(this);
                  }}
                >
                  {__("loginReconnect")}
                </button>
              </div>
            );
          } else {
            return (
              <div className="login-info">
                <div className="login-reconnect">
                  <div className="svg" dangerouslySetInnerHTML={{ __html: svg_no_connect }} />
                  <span>{__("loginUserServerLogout")}</span>
                </div>
                <button
                  className="loginBtn"
                  onClick={() => {
                    location.reload();
                  }}
                >
                  {__("login")}
                </button>
              </div>
            );
          }
          break;
        }
      case LoginState.checkInfo:
      case LoginState.login:
        img = "img/connecting.svg";
        text = __("loginConnecting") + "...";
        break;
      case LoginState.wait2FResult:
      case LoginState.waitToTwoFactorConfirm:
        img = "img/devices.svg";
        text = __("loginTwoFactor") + "...";
        break;
      case LoginState.success:
        img = "img/check.svg";
        text = __("loginVerified") + "...";
        cls = "loginBusyIndicatorFullBorder";
        break;
      case LoginState.error:
        return (
          <div className="login-info">
            <div className="login-err">
              <div className="no-shrink">
                <img src={"img/error.red.svg"} className="imgErr" alt="Error" />
              </div>
              <div className="error-message">
                <span>{this.state.errorMessage}</span>
                {this.autoLogin &&
                  (false /*&& this.reconnectCountExceeded*/ ? (
                    <span>{__("loginRecconnectCountExceeded")}</span>
                  ) : this.state.nextTryIn < 0 ? (
                    <span>{__("loginRecconnectAttempt")}...</span>
                  ) : (
                    <span>
                      {__("loginToRecconnectAttempt")} {this.state.nextTryIn} s.
                    </span>
                  ))}
              </div>
            </div>
            <button
              className={css.loginBtn}
              onClick={() => {
                location.reload();
              }}
            >
              {__("repeate")}
            </button>
            <button className="loginBtn reconnectBtn" onClick={this.changeUser}>
              {__("loginChangeUser")}
            </button>
          </div>
        );
      case LoginState.selectSession:
        let content: JSX.Element = undefined;
        if (this.state.sessions && this.state.sessions.length > 0) {
          content = (
            <>
              {this.state.sessions.map((server, i) => {
                return (
                  <div key={`session_${i}`} style={{ display: "contents" }}>
                    {server.List.map((item, index) => {
                      return <SessionBox key={index} item={item} closeSession={this.closeSession} loginToSesion={this.loginToSesion}></SessionBox>;
                    })}
                  </div>
                );
              })}
            </>
          );
        } else {
          content = (
            <div className="session-msg-center">
              <p>{__("loginAllSessionSucDisconnect")}</p>
            </div>
          );
        }
        return (
          <div className={`login-info ${this.selectSesssionClass()}`}>
            <div className="select-session-title">{__("loginAvailableSessions")}</div>
            <div className="select-session-container">{content}</div>
            <div className="select-loginBtn-wrap">
              <button className="loginBtn session-loginBtn" onClick={this.login}>
                <div style={{ justifyContent: "center", alignItems: "center" }}>
                  <span
                    style={{ backgroundRepeat: "no-repeat", backgroundImage: "url(img/add_connect.svg)", height: "20px", width: "20px", marginRight: "8px" }}
                  />
                  <p>{__("loginNew")}</p>
                </div>
              </button>
            </div>
          </div>
        );
      case LoginState.closeSession:
        img = "img/session.svg";
        text = __("sessionClose") + "...";
        cls = "loginBusyIndicatorRotate " + "loginBusyIndicatorBorder";
        break;
      default:
        break;
    }

    return (
      <div className={`login-info ${cls}`}>
        <K2BusyIndicator visibility={true} title={text} refreshBtn={this.props.reconnect !== undefined} backgroundShadow={false} disableModal={true}>
          {img && <img src={img} className="imgInfo" alt={__("loading")} />}
        </K2BusyIndicator>
      </div>
    );
  }

  reloadPage = async () => {
    await Helper.sendErrorMessage("cancel reconnect in login view", null);
    location.reload();
  };

  changeUser = () => {
    clearLoginCookie(true);
    location.reload();
  };

  loginToSesion = (id: string) => {
    this.setState({ sessionId: id, requestState: undefined, loginState: this.state.requestState });
  };

  closeSession = (sid: string) => {
    this.setState({ sessionId: sid, loginState: LoginState.closeSession });
  };

  login = () => {
    this.setState({ requestState: undefined, loginState: this.state.requestState });
  };

  selectSesssionClass = (): string => {
    return this.state.loginState === LoginState.selectSession ? "selectSession" : "";
  };

  private LoginFormContent(): JSX.Element {
    switch (this.state.loginType) {
      case LoginType.standard: {
        return (
          <>
            <div className="input-field">
              <label htmlFor="k2_user">
                <span>{__("loginName")}</span>
              </label>
              <input
                title={__("loginName")}
                type="text"
                id="k2_user"
                data-k2-test-id="k2_user"
                autoComplete="username"
                autoFocus
                value={this.state.userId}
                onChange={(e) => {
                  this.setState({ userId: e.currentTarget.value });
                }}
                ref={(ref) => {
                  if (ref) this.focusField = ref;
                }}
              />
            </div>

            <div className="input-field">
              <label htmlFor="k2_pwd">{__("loginPassword")}</label>
              <div className="password-wrapper">
                <input
                  title={__("loginPassword")}
                  type="password"
                  id="k2_pwd"
                  data-k2-test-id="k2_pwd"
                  onChange={(e) => {
                    this.setState({ password: e.currentTarget.value });
                  }}
                  ref={this.inputRef}
                  autoComplete="password"
                  className="input-password"
                />
                <button
                  type="button"
                  className="reveal-password-btn"
                  onClick={this.revealPassword}
                  title={this.state.revealPassword ? __("loginHidePassword") : __("loginShowPassword")}
                >
                  {this.state.revealPassword ? svg_no_eye : svg_eye}
                </button>
              </div>
            </div>
          </>
        );
      }
      case LoginType.pin: {
        return (
          <>
            <div className="pin-login-wrap">
              <div className="input-field">
                <label htmlFor="k2_pwd">PIN</label>
                <div className="password-wrapper">
                  <input
                    title="PIN"
                    type="password"
                    id="k2_pin"
                    data-k2-test-id="k2_pin"
                    autoComplete="off"
                    autoFocus
                    inputMode="numeric"
                    value={this.state.userId} // Ruční nastavení hodnoty
                    onChange={(e) => {
                      // Povolení pouze čísel
                      const regex = /^[0-9\b]+$/;
                      if (e.target.value === "") {
                        this.setState({ userId: "" });
                      } else if (regex.test(e.target.value)) {
                        this.setState({ userId: e.currentTarget.value });
                      } else {
                        e.preventDefault();
                      }
                    }}
                    ref={(ref) => {
                      if (ref) this.focusField = ref;
                    }}
                    className="input-password"
                  />
                </div>
              </div>
              <div className="pin-button-wrap">
                {pinKeyboard.map((el) => {
                  return (
                    <span className="pin-button-item" key={"pin-button-" + el}>
                      <button
                        onClick={(e) => {
                          this.pinButtonAction(el);
                          e.preventDefault();
                        }}
                        title={el}
                      >
                        {el === "CLEAR" ? svg_clear : el === "DEL" ? svg_del : el}
                      </button>
                    </span>
                  );
                })}
              </div>
            </div>
          </>
        );
      }
      case LoginType.card: {
        return (
          <>
            <div className="card-info-wrap" title={__("loginAttachCardReader")}>
              <div className="card-info-svg">{svg_wireless}</div>
              <div className="card-info-text">{__("loginAttachCardReader")}</div>
            </div>
            <div className="password-wrapper card-input-wrap">
              <input
                title="Karta"
                type="text"
                id="k2_card"
                data-k2-test-id="k2_card"
                autoComplete="off"
                onChange={(e) => {
                  this.setState({ userId: e.currentTarget.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    this.setState({ loginState: LoginState.checkInfo });
                  }
                }}
                ref={(ref) => {
                  if (ref) this.focusField = ref;
                }}
                className="input-password"
              />
            </div>
          </>
        );
      }
    }
  }
  switchType = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.state.loginType === LoginType.standard) this.setState({ loginType: LoginType.pin });
    if (this.state.loginType === LoginType.pin) this.setState({ loginType: LoginType.card });
    if (this.state.loginType === LoginType.card) this.setState({ loginType: LoginType.standard });

    e.preventDefault();
  };

  initTypeActions = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (this.state.loginType === LoginType.card || this.state.loginType === LoginType.pin) {
      this.focusField.focus();
    }
    const USvalue = KeyboardInput_LocalToUSKeys(e);
    if (this.state.loginType === LoginType.card && USvalue !== null && USvalue !== e.key) {
      this.focusField.value += USvalue;
      this.setState({ userId: this.focusField.value });
      e.preventDefault();
    }
  };

  pinButtonAction = (action: string) => {
    if (action === "DEL") {
      let val = this.focusField.value;
      val = val.slice(0, -1);
      this.focusField.value = val;
    } else if (action === "CLEAR") {
      this.focusField.value = "";
    } else {
      this.focusField.value += action;
    }
    this.setState({ userId: this.focusField.value });
  };

  getLoginBtn = (): JSX.Element => {
    return (
      <div className="loginBtn-container">
        {this.state.loginType === LoginType.standard && (
          <button type="submit" className="loginBtn" title={__("login")}>
            {__("login")}
          </button>
        )}
        <button className="typeSwitchBtn" onClick={this.switchType} title={__("loginType")}>
          {this.state.loginType === LoginType.standard ? svg_keyboard : this.state.loginType === LoginType.pin ? svg_pin : svg_card}
        </button>
      </div>
    );
  };

  private getLoginForm(): JSX.Element {
    return (
      <form action="#" className="two-row" data-k2-test-id="k2_loginForm" onSubmit={this.onSubmit}>
        <div className="block">
          <div className="inputs">
            {this.LoginFormContent()}

            {Context.DeviceInfo.AutoLogin == "1" && (
              <div className="remember-password">
                <input type="checkbox" id="remember-password_input" onClick={this.handleRememberPassword} title={__("loginRemember")} />
                <label htmlFor="remember-password_input" className="remember-password_label">
                  <span className="checkbox"></span>
                  {__("loginRemember")}
                </label>
              </div>
            )}
          </div>
          <MediaQuery maxWidth={"220mm"}>
            <div>{this.getLoginBtn()}</div>
          </MediaQuery>
        </div>
        <MediaQuery minWidth={"220mm"}>
          <div className="login-bottom-wrap">
            <div className="cleaner height30"></div>
            <div className="two-column">
              <div className="info">
                <b>
                  {this.props.ver} - {__("loginWebClient")}
                </b>
                <br />
                {__("loginLicenceHolder")}: <span data-k2-test-id="licenseHolder">{this.props.licenseHolder}</span> <br />
                <span data-k2-test-id="version">
                  {this.props.ver} ({__("loginVersion")}: {this.state.version})
                </span>
              </div>
              {this.getLoginBtn()}
            </div>
            <div className="cleaner height30"></div>
            <div className="two-column">
              <div className="info">
                &copy; K2 software s.r.o.
                <br />
                <a href={this.props.ToSLink}>{__("loginTermsOfUse")}</a>
              </div>
              <div className="logo-footer">
                <a href="https://www.k2.cz" target="_blank" rel="noreferrer" title="K2 software s.r.o.">
                  <img src={this.props.footerImg} alt="K2 software s.r.o." />
                </a>
              </div>
            </div>
          </div>
        </MediaQuery>
        {/* Mobilní vzhled se liší od desktopu, je potřeba jiná struktura */}
        <MediaQuery maxWidth={"220mm"}>
          <div ref={this.infoWrap} className="login-bottom-wrap">
            <div>
              <div className="logo-footer">
                <a href="https://www.k2.cz" target="_blank" rel="noreferrer" title="K2 software s.r.o.">
                  <img src={this.props.footerImg} alt="K2 software s.r.o." />
                </a>
              </div>
            </div>
            <div className="cleaner height30"></div>
            <div className="two-column">
              <div className="info">
                <b>
                  {this.props.ver} - {__("loginWebClient")}
                </b>
                <br />
                {__("loginLicenceHolder")}: <span data-k2-test-id="licenseHolder">{this.props.licenseHolder}</span> <br />
                <span data-k2-test-id="version">
                  {this.props.ver} ({__("loginVersion")}: {this.state.version})
                </span>
              </div>
            </div>
            <div className="cleaner height30"></div>
            <div className="two-column">
              <div className="info">
                &copy; K2 software s.r.o.
                <div className="cleaner height30"></div>
                <a href={this.props.ToSLink}>{__("loginTermsOfUse")}</a>
              </div>
            </div>

            <div className="mobile-bottom-closer" onClick={this.toggleInfoWrapState}>
              <span>{__("close")}</span>
            </div>
          </div>
          <div className="mobile-bottom-opener fake_link">
            <span onClick={this.toggleInfoWrapState}>{__("loginProductInfo")}</span>
          </div>
        </MediaQuery>
      </form>
    );
  }

  toggleInfoWrapState = () => {
    if (this.infoWrap.current.classList.contains("active")) {
      this.infoWrap.current.classList.remove("active");
    } else this.infoWrap.current.classList.add("active");
  };

  revealPassword = () => {
    this.setState({ revealPassword: !this.state.revealPassword }, () => {
      this.inputRef.current.type = this.state.revealPassword ? "text" : "password";
    });
  };

  handleRememberPassword = (e: React.MouseEvent<HTMLInputElement>) => {
    if (e.currentTarget.checked) {
      this.rememberLogin = true;
    } else {
      this.rememberLogin = false;
    }
  };

  async componentDidUpdate(prevProps: LoginViewProps, prevState: LoginViewState) {
    this.resetCountdown();

    if (this.state !== prevState) {
      if (this.state.localizationVersion !== prevState.localizationVersion) {
        this.setState({ localizationVersion: this.state.localizationVersion });
      }
      if (this.state.loginState !== prevState.loginState) {
        switch (this.state.loginState) {
          case LoginState.checkInfo:
            {
              const version = await isNewVersionAvailable();

              if (version === Version.new) {
                this.setState({ loginState: LoginState.error, errorMessage: __("loginNewVersionAvailable") });
                return;
              }

              Context.getApplication()
                .getLoginInfo(this.state.userId, this.state.password, this.useQuickLogin())
                .then((result) => {
                  let nextState = LoginState.login;
                  if (result && result.TwoFactorAuthentification == true) {
                    nextState = LoginState.waitToTwoFactorConfirm;
                  }

                  if (this.autoLogin) {
                    delete result.Sessions;
                  }

                  this.setState({
                    loginState: result.Sessions && result.Sessions.length > 0 ? LoginState.selectSession : nextState,
                    requestState: result.Sessions && result.Sessions.length > 0 ? nextState : undefined,
                    sessions: result.Sessions,
                    sessionsVersion: Date.now(),
                    wsid: result.WSID,
                  });
                })
                .catch(async (reason) => {
                  await this.handleRejection(reason);
                });
            }
            break;
          case LoginState.waitToTwoFactorConfirm:
          case LoginState.login:
            // Pro pripad, ze se navaze pripojeni s IIS, a nedojde tak k vyjimce a zavolani metody handleRejection v predchazejicim kroku

            if (this.reconnect()) return;

            Context.getApplication()
              .login(this.state.userId, this.state.sessionId, this.useQuickLogin(), this.state.password)
              .then((result) => {
                this.setState({ loginState: LoginState.success, loginData: result });
              })
              .catch(async (reason) => {
                if (reason instanceof CloseEvent) {
                  if (this.state.loginState === LoginState.waitToTwoFactorConfirm && this.state.wsid) {
                    this.setState({ loginState: LoginState.wait2FResult });
                    return;
                  }
                }

                await this.handleRejection(reason);
              });

            break;
          case LoginState.wait2FResult:
            if (this.state.wsid) {
              Context.getApplication()
                .wait2FResult(this.state.wsid)
                .then((result) => {
                  this.setState({ loginState: LoginState.success, loginData: result });
                })
                .catch(async (reason) => {
                  await this.handleRejection(reason);
                });
            }
            break;
          case LoginState.success:
            if (this.rememberLogin && !this.useQuickLogin()) {
              const hash = CryptoJS.AES.encrypt(this.state.password, K2Key).toString();
              document.cookie = `login=${this.state.userId},${hash},true;expires=01 Jan 2100 12:00:00 UTC`;
            } else {
              clearLoginCookie(true);
            }
            await sleep(300);
            Context.getApplication().realizeApp(this.state.loginData, this.state.userId);
            break;
          case LoginState.closeSession:
            if (!this.state.sessionId) {
              this.setState({ loginState: LoginState.selectSession, sessionId: undefined, sessionsVersion: Date.now() });
            } else {
              Context.getApplication()
                .closeSession(this.state.userId, this.state.sessionId, this.useQuickLogin(), this.state.password)
                .then((value) => {
                  if (value) {
                    const st = this.state.sessions;
                    let ndx = -1;
                    st.map((item, i) => {
                      item.List.map((info, index) => {
                        if (info.ID === this.state.sessionId) {
                          ndx = index;
                          return;
                        }
                      });
                      if (ndx >= 0) {
                        item.List.splice(ndx, 1);
                        if (item.List.length === 0) {
                          ndx = i;
                        } else {
                          ndx = -1;
                        }
                        return;
                      }
                    });
                    if (ndx >= 0) {
                      st.splice(ndx, 1);
                    }

                    this.setState({ sessions: st, sessionsVersion: Date.now(), loginState: LoginState.selectSession, sessionId: undefined });
                  }
                })
                .catch((reason) => {
                  this.handleRejection(reason);
                });
            }
            break;
          default:
            break;
        }
      }
      if (this.state.loginType === LoginType.pin && this.state.userId != prevState.userId && this.state.userId.length === Context.DeviceInfo.LoginPinLength) {
        this.setState({ loginState: LoginState.checkInfo });
      }
    }

    // Spusti se znovu timer v pripade, ze prijde dalsi pozadavek na vykresleni reconnect formulare
    if (prevProps.reconnect !== this.props.reconnect) {
      this.handleRejection(this.state.errorMessage);

      return;
    }

    //show error
    if (prevProps.error != this.props.error) {
      this.autoLogin = this.rememberLogin;
      this.handleRejection(this.props.error.message);
    }

    if (!this.autoLogin || this.state.nextTryIn < -1 /*|| this.reconnectCountExceeded*/) return;

    // Countdown
    if (this.state.nextTryIn !== prevState.nextTryIn) {
      this.countdown = window.setTimeout(() => {
        this.setState({ nextTryIn: this.state.nextTryIn - 1 });
      }, 1000);
    }

    // Pokus o (re)connect
    if (this.state.loginState === LoginState.error && this.state.nextTryIn === -1) {
      if (this.reconnect()) return;

      this.setState({ loginState: LoginState.checkInfo, errorMessage: undefined });
    }
  }

  private useQuickLogin(): boolean {
    return this.state.loginType !== LoginType.standard;
  }

  handleRejection = async (reason: any) => {
    this.errorTries += 1;
    const nextTry = /*this.errorTries **/ RECONNECT_DELAY;

    // if (this.errorTries > MAX_RECONNECT_COUNT) this.reconnectCountExceeded = true;

    if (reason instanceof Blob) {
      const text = await reason.text();
      this.setState({ loginState: LoginState.error, errorMessage: JSON.parse(text)?.json, nextTryIn: nextTry });
    } else {
      this.setState({ loginState: LoginState.error, errorMessage: reason, nextTryIn: nextTry });
    }
  };

  reconnect = () => {
    if (this.props.reconnect) {
      this.props.reconnect.reconnectFce.call(this);

      return true;
    }

    return false;
  };

  onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.state.userId && this.state.loginState === LoginState.none) {
      this.setState({ loginState: LoginState.checkInfo });
    } else {
      this.shakeOn();
    }
  };

  componentWillUnmount(): void {
    this.resetCountdown();
  }

  resetCountdown = () => {
    if (this.countdown) {
      window.clearTimeout(this.countdown);
    }
  };

  async shakeOn() {
    this.focusField.focus();
    this.focusField.parentElement.classList.add("shakeOn");
    await sleep(1000);
    this.focusField.parentElement.classList.remove("shakeOn");
  }
}
